.users-container {
    display: flex;
    background-color: var(--gray50);
    height: 100vh;
    width: 100%;
    overflow-x: auto;
}
    .users-container-main {
        margin-left: 103px;
        width: 100%;
    }
        .users-container-main-hedear {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            position: fixed;
            width: 91%;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 60px;
            z-index: 4;
            background-color: var(--gray50);
        }
            .users-container-main-hedear-iconWrapper {
                margin-right: 270px;
            }