.DateBetweenComponent-container {
  display: flex;
  flex-direction: column;
  width: 95%;
}
  .DateBetweenComponent-container-text {
    color: var(--gray600);
    margin-bottom: 2px;
    font-size: 14px;
  }
  .DateBetweenComponent-container-inputContainer {
    position: relative;
    display: inline-block;
  }
    .DateBetweenComponent-container-inputContainer-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      border-radius: 15px;
      border: 1px solid var(--gray300);
      background: var(--white);
      padding: 10px;
      width: 100%;
      outline: none;
      font-family: 'Lato';
      color: var(--gray700);
    }

    .DateBetweenComponent-container-inputContainer-input::placeholder {
      color: var(--gray300);
      font-weight: 300;
      font-style: normal;
      font-size: 0.875rem;
      line-height: 1rem;
    }
  .DateBetweenComponent-container-error {
    color: var(--errorPrimary);
  }

  ::-webkit-calendar-picker-indicator {
    /* unica manera de llevar el picker a primary500 es a traves de filters https://codepen.io/sosuke/pen/Pjoqqp*/
    filter: invert(24%) sepia(94%) saturate(1886%) hue-rotate(185deg) brightness(95%) contrast(103%);
    cursor:pointer;
  }

 input[type=date]{
  text-transform: uppercase;
 }

