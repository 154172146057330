.polizas-container {
  display: flex;
  background-color: var(--white2);
  height: 100vh;
  width: 100%;
}
.polizas-container-main {
  margin-left: 103px;
  width: 100%;
}
.polizas-container-main-hedear {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  position: fixed;
  width: 92%;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 60px;
  z-index: 4;
  background-color: var(--white2);
}
.polizas-container-main-hedear-iconWrapper {
  margin-right: 270px;
}
