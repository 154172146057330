.tableContent-container {
    width: 100%;
    padding-bottom: 25px;
}
@media only screen and (max-width: 1200px) and (min-width: 1000px) {
    .tableContent-container {
        padding-right: 200px;
    }
}
@media only screen and (max-width: 1000px) and (min-width: 900px) {
    .tableContent-container {
        padding-right: 300px;
    }
}
@media only screen and (max-width: 800px) and (min-width: 700px) {
    .tableContent-container {
        padding-right: 400px;
    }
}
@media only screen and (max-width: 700px) and (min-width: 600px) {
    .tableContent-container {
        padding-right: 500px;
    }
}
    .tableContent-container-tableHeadeContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .tableContent-container-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 60px;
    }
        .tableContent-container-empty-title {
            color: var(--gray700);
            margin: 8px 0;
        }
        .tableContent-container-empty-text {
            color: var(--gray400);
            text-align: center;
        }
    .tableContent-container-pageContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 20px 0;
        margin-left: 20px;
    }
        .tableContent-container-pageContainer-pages {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 auto;
        }
            .tableContent-container-pageContainer-pages-wrap {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            .tableContent-container-pageContainer-pages-text {
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                color: var(--primary500);
                margin: 0 10px;
            }
            .tableContent-container-pageContainer-pages-number {
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                color: var(--gray300);
                margin: 0 5px;
            }
        .tableContent-container-table-global-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
        }
            .tableContent-container-table-global-content-actions {
                display: flex;
                flex-direction: row;
                align-items: center;
                position: relative;
            }
                .tableContent-container-table-global-content-actions-list {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    left: 37.5px;
                    top: 30px;
                    border-radius: 15px;
                    background-color: var(--white);
                    box-shadow: 0px 15px 30px 0px rgba(51, 51, 51, 0.20);
                    overflow: hidden;
                    width: max-content;
                }
                    .tableContent-container-table-global-content-actions-list-text {
                        color: var(--gray600);
                        padding: 6px 16px;
                        cursor: pointer;
                    }
                    .tableContent-container-table-global-content-actions-list-text:hover {
                        background-color: var(--gray100);
                    }
                    .tableContent-container-table-global-content-actions-list-text:active {
                        background-color: var(--primary200);
                    }
                        .tableContent-container-table-global-content-actions-list-text-estado {
                            padding: 3px;
                            border-radius: 5px;
                            margin-left: 5px;
                        }
                .tableContent-container-table-global-content-actions-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 25px;
                    width: 24px;
                    height: 24px;
                    margin-left: 8px;
                    cursor: pointer;
                    position: relative;
                }
                    .tableContent-container-table-global-content-actions-item-img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                    }
                    .tableContent-container-table-global-content-actions-item-img:hover .tooltip {
                        visibility: visible;
                        opacity: 1;
                    }
                        .tableContent-container-table-global-content-actions-item-img-img {
                            width: 100%;
                            transition: all 0.5s ease;
                        }
                            .tableContent-container-table-global-content-actions-item-img-img:active {
                                transform: scale(0.8)
                            }
            .tableContent-container-table-global-content-selection {
                transition: all 0.5ms ease;
            }
                .tableContent-container-table-global-content-selection-list {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    left: 0px;
                    top: 30px;
                    border-radius: 15px;
                    box-shadow: 0px 15px 30px 0px rgba(51, 51, 51, 0.20);
                }
                    .tableContent-container-table-global-content-selection-list-text {
                        color: var(--gray600);
                        padding: 6px 16px;
                        cursor: pointer;
                        background-color: var(--white);
                        z-index: 2;
                    }
                    .tableContent-container-table-global-content-selection-list-text:hover {
                        background-color: var(--gray100);
                    }
                    .tableContent-container-table-global-content-selection-list-text:active {
                        background-color: var(--primary200);
                    }
        .tableContent-container-pageContainer-totalText {
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            color: var(--gray300);
            margin: 0;
        }
        .tableContent-container-table-global-content-box {
            display: flex;
            position: relative;
        }
        .tableContent-container-table-global-content-box:hover .tooltip-box {
            visibility: visible;
            opacity: 1;
        }
        .tableContent-container-table-global-box {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1.6px solid var(--primary500);
            border-radius: 5px;
            width: 18px;
            height: 18px;
            cursor: pointer;
            margin-left: 10px;
        }
    #main-table {
        border-collapse: collapse;
        width: 100%;
    }
        .table-rowHover {
            cursor: pointer;
        }
        .table-rowHover:hover {
            background-color: var(--gray100);
        }
        #main-table th {
            height: 58px;
            text-align: left;
            background-color: var(--gray100);
            color: var(--gray300);
            padding: 0 10px;
            font-size: 12px;
            margin: 0;
            width: 5%;
        }
        #main-table .tableContent-container-table-global {
            height: 40px;
            text-align: left;
            background-color: var(--white);
            color: var(--gray300);
            padding: 0 10px;
            font-size: 12px;
            margin: 0;
        }
        #main-table .tableContent-container-table-empty {
            height: 58px;
            text-align: left;
            background-color: var(--gray100);
            color: var(--gray300);
            padding: 0 10px;
            font-size: 12px;
            margin: 0;
        }
        #main-table td {
            text-align: left;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: var(--gray200);
            height: 50px;
            padding: 0 10px;
        }
    .props.goToDetails {

    }
.tooltip {
    visibility: hidden;
    width: max-content;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-weight: 400;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the text */
    left: 50%;
    margin-left: -60px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}
.tooltip-box {
    visibility: hidden;
    width: max-content;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-weight: 400;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the text */
    left: 50%;
    margin-left: -20px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}