.ordenarPor-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left:auto;
}
.ordenarPor-container-labels {
  display: flex;
  flex-direction: row;
}
.ordenarPor-container-labels-text {
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  margin: 0;
}
.ordenarPor-container-list {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: var(--white);
  box-shadow: 0px 10px 20px rgba(51, 51, 51, 0.25);
  border-radius: 15px;
  position: absolute;
  margin-top: 30px;
  z-index: 3;
}
.ordenarPor-container-list-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  color: var(--black);
  padding: 10px 20px;
  cursor: pointer;
}
.ordenarPor-container-list-text:hover {
  background-color: var(--white3);
}
.ordenarPor-container-list-text:active {
  background-color: var(--primary500);
  color: var(--white);
}
