.genericTableAndFilters-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 120px;
}
  .genericTableAndFilters-container-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    overflow-x: hidden;
    position: relative;
  }
    .genericTableAndFilters-container-title-texts {
      display: flex;
      flex-direction: column;
    }
      .genericTableAndFilters-container-title-texts-title {
        color: var(--gray700);
        margin-left: 20px;
      }
      .genericTableAndFilters-container-title-texts-subTitle {
        color: var(--gray700);
        margin-left: 20px;
      }
    .genericTableAndFilters-container-title-buttonWrapper {
      margin-left: auto;
      margin-right: 10px;
      width: 15%
    }
    
  .genericTableAndFilters-container-table {
    width: 100%;
    margin-top: 20px;
  }
